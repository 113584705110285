.stickyHeader {
    position: sticky;
    left: 0;
    z-index: 3;
    background: white;
}

.sticky {
    position: sticky;
    left: 0;
    background: white;
}